import React from "react";
import { Container } from "react-bootstrap";
import ClientsFooter from "../../components/ClientsFooter";

import MeetJx from "./MeetJx";
import Values from "./Values";
import Process from "./Process";
import Founder from "./Founder";

const About = () => {
  return (
    <>
      <Container>
        <div className="flexContainer aboutus">
          <MeetJx />
          <Founder />
          {/* <Team /> */}
          <Values />
          <Process />
        </div>
      </Container>
      <ClientsFooter />
    </>
  );
};

export default About;
