import React from "react";
import "./LogoSlider.css";

import HVALogo from "./../img/Clients Logo Light/hva_ceylon_coconut_milk_organic_wholesale_foodandbeverage_vector_logo_light.svg";
import CargoLogo from "./../img/Clients Logo Light/cargo_login_frieght_fowarding_logistics_supplychain_vector_logo_light.svg";
import ANZLogo from "./../img/Clients Logo Light/anz _banking_vector_logo_light.svg";
import AusPostLogo from "./../img/Clients Logo Light/australiapost_mail_post_vector_logo_light.svg";
import YalumbaLogo from "./../img/Clients Logo Light/yalumba_winery_foodandbeverage_vector_logo_light.svg";
import BupaLogo from "./../img/Clients Logo Light/bupa_health_insurance_vector_logo_light.svg";
import KojoLogo from "./../img/Clients Logo Light/kojo_digital_vector_logo_light.svg";
import RMITLogo from "./../img/Clients Logo Light/royal_melbourne_institute_technology_vector_logo_light.svg";
import BOALogo from "./../img/Clients Logo Light/bankofamerica_banking_vector_logo_light.svg";
import StandCharLogo from "./../img/Clients Logo Light/standard_chartered_banking_vector_logo_light.svg";
import AGLLogo from "./../img/Clients Logo Light/agl_energy_vector_logo_light.svg";
import NABLogo from "./../img/Clients Logo Light/national_australia_bank_banking_vector_logo_light.svg";

import phenixLogo from "./../img/Clients Logo Light/phenix_light_logo.svg";
import hostLogo from "./../img/Clients Logo Light/host.svg";
import lasendaLogo from "./../img/Clients Logo Light/lasenda.svg";
import virtikLogo from "./../img/Clients Logo Light/virtik.svg";

const LogoSlider = () => {
  const logos = [
    { alt: "Phenix", width: 176, height: 60, img: phenixLogo },
    { alt: "LaSenda", width: 170, height: 26, img: lasendaLogo },
    { alt: "Host", width: 92, height: 29, img: hostLogo },
    { alt: "Virtik", width: 170, height: 27, img: virtikLogo },
    { alt: "Bank of America", width: 153, height: 62.86, img: BOALogo },
    { alt: "HVA Ceylon", width: 88, height: 52.13, img: HVALogo },
    { alt: "Cargo Login", width: 124, height: 51, img: CargoLogo },
    { alt: "ANZ", width: 147.93, height: 47, img: ANZLogo },
    { alt: "Australia Post", width: 144.07, height: 51.2, img: AusPostLogo },
    { alt: "Yalumba", width: 98.55, height: 57.07, img: YalumbaLogo },
    { alt: "AGL", width: 73.97, height: 70.46, img: AGLLogo },
    { alt: "Kojo", width: 82.51, height: 54, img: KojoLogo },
    { alt: "Bupa", width: 67, height: 67, img: BupaLogo },
    {
      alt: "Standard Chartered",
      width: 163.61,
      height: 64,
      img: StandCharLogo,
    },
    { alt: "RMIT University", width: 128.54, height: 44.56, img: RMITLogo },
    { alt: "NAB", width: 125.08, height: 53, img: NABLogo },
  ];

  return (
    <div style={{ paddingBottom: 96, paddingTop: 96 }}>
      <div className="slider">
        <div className="slide-track">
          {logos.concat(logos).map((el, idx) => (
            <div className="slide" key={idx}>
              <img
                src={el.img}
                width={el.width}
                height={el.height}
                alt={el.alt}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogoSlider;
