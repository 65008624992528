import { React, useState, useEffect, lazy } from "react";
import { Container } from "react-bootstrap";
import Hero from "./Hero";
import Partners from "./Partners";
import LogoSlider from "../../components/LogoSlider";

const Heading = lazy(() => import("./Heading"));
const What = lazy(() => import("./What"));
const Process = lazy(() => import("./Process"));
// const Funding = lazy(() => import("./Funding"));
const Aboutus = lazy(() => import("./Aboutus"));
const Youtube = lazy(() => import("./Youtube"));

const Homepage = () => {
  const [showYoutube, setShowYoutube] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1100) {
        setShowYoutube(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowYoutube(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const toggleYoutube = () => {
    setShowYoutube(!showYoutube);
  };
  return (
    <>
      <Youtube toggleYoutube={toggleYoutube} showYoutube={showYoutube} />
      <Container className="homepage"></Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Hero />
        <LogoSlider />
        <What />
        {/* <Funding /> */}
        <Process />
        <Heading />
      </div>
      <Container className="homepage">
        <Partners />
        <Aboutus />
      </Container>
    </>
  );
};

export default Homepage;
