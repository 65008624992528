import { React } from "react";
import { Container } from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";

import CaseStudiesMap from "../../components/CaseStudiesMap";
import ClientsFooter from "../../components/ClientsFooter";

import AglVideo from "./../../video/case-studies/agl452.webm";
import AnzVideo from "./../../video/case-studies/anz452.webm";
import BupaVideo from "./../../video/case-studies/bupa452.webm";
import YulumbaVideo from "./../../video/case-studies/yulumba452.webm";
import LoonumVideo from "./../../video/case-studies/loonum452.webm";
import PhenixVideo from "./../../video/case-studies/phenix452.webm";

const CaseStudies = () => {
  const caseStudies1 = [
    {
      title: "ANZ",
      subtitle: "UX Research & UI Design",
      videoUri: AnzVideo,
      height: "452px",
      style: {
        marginBottom: 16,
      },
    },
    {
      title: "Bupa",
      subtitle: "Digital Service Design",
      videoUri: BupaVideo,
      height: "452px",
      style: {
        marginBottom: 16,
      },
    },
    {
      title: "Loonum",
      subtitle: "UX & Digital Service Design",
      videoUri: LoonumVideo,
      height: "452px",
      style: {
        marginBottom: 16,
      },
    },
    {
      title: "AGL",
      subtitle: "UX & Digital Service Design",
      videoUri: AglVideo,
      height: "452px",
      style: {
        marginBottom: 16,
      },
    },
    {
      title: "Phenix",
      subtitle: "UX & Digital Service Design",
      videoUri: PhenixVideo,
      height: "452px",
      style: {
        marginBottom: 16,
      },
    },
    {
      title: "Yalumba",
      subtitle: "UX & Digital Service Design",
      videoUri: YulumbaVideo,
      height: "452px",
      style: {
        marginBottom: 16,
      },
    },
  ];

  // const caseStudies2 = [
  //   {
  //     title: "Australia Post",
  //     subtitle: "CX & Digital Service Design",
  //     img: AusPostPhoto,
  //     height: "510px",
  //     style: {
  //       marginBottom: 89,
  //     },
  //   },
  //   {
  //     title: "ANZ",
  //     subtitle: "UX Research & UI Design",
  //     img: ANZPhoto,
  //     height: "510px",
  //     style: {
  //       marginBottom: 89,
  //     },
  //   },
  //   {
  //     title: "Parachute",
  //     subtitle: "UX Research & Design",
  //     img: ParachuutePhoto,
  //     height: "460px",
  //     style: {
  //       marginBottom: 71,
  //     },
  //   },
  //   {
  //     title: "Lacrosse",
  //     subtitle: "UXUI Design & UI Development",
  //     img: LacrossePhoto,
  //     height: "430px",
  //     style: {},
  //   },
  // ];
  return (
    <>
      <Container className="flexContainer">
        <div style={{ marginTop: 90 }}>
          <VisibilitySensor partialVisibility delayedCall>
            {({ isVisible }) => (
              <h1
                className={`reveal casestudies_h1 ${isVisible ? "active" : ""}`}
              >
                What we have developed
              </h1>
            )}
          </VisibilitySensor>
          <div className="">
            <div style={{ display: "flex", flexWrap: "wrap", gap: 29 }}>
              <CaseStudiesMap cases={caseStudies1} />
            </div>
            {/* <div>
              <CaseStudiesMap cases={caseStudies2} />
            </div> */}
          </div>
        </div>
      </Container>
      <ClientsFooter />
    </>
  );
};

export default CaseStudies;
