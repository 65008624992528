import React from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

const Clients = () => {
  const navigate = useNavigate();
  const handleNavigate = (url) => {
    navigate(url);
  };
  return (
    <>
      <h2
        className="reveal homepage_clients_h2"
        style={{ paddingBottom: "48px", paddingTop: "96px" }}
      >
        Case Studies
      </h2>
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {/* Video 1 */}

          <div
            style={{
              width: "957px",
              paddingTop: "20px",
              overflow: "hidden",
              cursor: "pointer",
            }}
            onClick={() => handleNavigate("/case-studies/Bupa")}
          >
            <ReactPlayer
              className="reactplayer_heading"
              url={require("../../img/Home Page/bupa519.webm")}
              playing={true}
              playsinline={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </div>
          {/* Video 2 */}
          <div
            style={{
              width: "296px",
              height: "540px",
              paddingTop: "20px",
              overflow: "hidden",
              cursor: "pointer",
            }}
            onClick={() => handleNavigate("/case-studies/ANZ")}
          >
            <ReactPlayer
              className="reactplayer_heading"
              url={require("../../img/Home Page/anz519.webm")}
              playing={true}
              playsinline={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </div>
          {/* Video 3 */}
          <div
            style={{
              width: "296px",
              height: "540px",
              paddingTop: "20px",
              overflow: "hidden",
              cursor: "pointer",
            }}
            onClick={() => handleNavigate("/case-studies/Loonum")}
          >
            <ReactPlayer
              className="reactplayer_heading"
              url={require("../../img/Home Page/loonum519.webm")}
              playing={true}
              playsinline={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </div>
          {/* Video 4 */}
          <div
            style={{
              width: "296px",
              height: "540px",
              paddingTop: "20px",
              overflow: "hidden",
              cursor: "pointer",
            }}
            onClick={() => handleNavigate("/case-studies/Phenix")}
          >
            <ReactPlayer
              className="reactplayer_heading"
              url={require("../../img/Home Page/phenix519.webm")}
              playing={true}
              playsinline={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </div>

          {/* Video 5 */}
          <div
            style={{
              paddingTop: "20px",
              overflow: "hidden",
              cursor: "pointer",
            }}
            onClick={() => handleNavigate("/case-studies/Yalumba")}
          >
            <ReactPlayer
              className="reactplayer_heading"
              url={require("../../img/Home Page/yulumba519.webm")}
              playing={true}
              playsinline={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Clients;
