import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Row, Col } from "react-bootstrap";
import jxdesignred from "../img/misc/jxdesignred.svg";
import loonumgroup from "../img/misc/loonumgroup.svg";
import jxsmall from "../img/misc/jxsmall.svg";
import loonumgroupsmall from "../img/misc/loonumgroupsmall.svg";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footerContainer w1460">
        <Col>
          <Row className="">
            <h1 className="footer_h1">Let's talk.</h1>
            <div className="footer_email_phone_container">
              <NavLink
                to="/contactus"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <button type="button" class="btn footer-btn-info">
                  Contact Us
                </button>
              </NavLink>
              <a
                aria-label="Give JX Design a call"
                href="tel:1 661 749 3778"
                className="footer_phone redHover"
              >
                +1 (661) 749 3778
              </a>
            </div>
          </Row>
          <Row className="footerAddress align-self-end">
            <div className="footer_p w1460">28358 Constellation Road,</div>
            <div className="footer_p w1460">Unit 600,</div>
            <div className="footer_p w1460">Valencia,</div>
            <div className="footer_p w1460">CA 91355, USA</div>
          </Row>
          <Row className="w1460" style={{ marginTop: 65 }}>
            <Col className="">
              <a
                aria-label="Visit the Loonum homepage"
                href="https://loonum.com"
              >
                <LazyLoadImage
                  className="footer_loonumLogo w1460"
                  alt="jx_digital_design_agency_servicedesign_uxui"
                  src={loonumgroup}
                />
              </a>
            </Col>
          </Row>
        </Col>
        <Col className="footer_copyrightContainer w1460">
          <div className="footer_p footer_copyright">JXTech.Ai @ 2024</div>
        </Col>
        <Col className="w1460" style={{ marginRight: -19 }}>
          <Row>
            <div style={{ textAlign: "left", marginTop: 23 }}>
              <a aria-label="Navigate to JX Design's Homepage" href="/">
                <LazyLoadImage
                  className="footer_jxLogo"
                  alt="jx_digital_design_agency_servicedesign_uxui"
                  src={jxdesignred}
                />
              </a>
            </div>
          </Row>
          <Row className="footer_socials">
            <div className="footerTopRight">
              <a
                aria-label="JX Design's Facebook"
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.facebook.com/profile.php?id=100089495397503"
              >
                <i className="redHover fa-brands fa-facebook"></i>
              </a>
              <a
                aria-label="JX Design's Twitter"
                target="_blank"
                rel="noreferrer noopener"
                href="https://twitter.com/jxdrive"
              >
                <i className="redHover fa-brands fa-twitter"></i>
              </a>
              <a
                aria-label="JX Design's Instagram"
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.instagram.com/jxdrive/"
              >
                <i className="redHover fa-brands fa-instagram"></i>
              </a>
              <a
                aria-label="JX Design's Linkedin"
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.linkedin.com/company/jx-design/about/"
              >
                <i className="redHover fa-brands fa-linkedin"></i>
              </a>
              <a
                aria-label="JX Design's Tiktok"
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.tiktok.com/@jxdrive?lang=en"
              >
                <i className="redHover fa-brands fa-tiktok"></i>
              </a>
              <a
                aria-label="JX Design's Youtube"
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.youtube.com/@jxdrive/featured"
              >
                <i className="redHover fa-brands fa-youtube"></i>
              </a>
            </div>
          </Row>
          <Row className="footerLinks">
            <a
              aria-label="JX Design's Work"
              className="redHover footer_p"
              href="/case-studies"
            >
              Work
            </a>
            <a
              aria-label="JX Design's Solutions"
              className="redHover footer_p"
              href="/solutions"
            >
              Solutions
            </a>
            <a
              aria-label="About JX Design"
              className="redHover footer_p"
              href="/aboutus"
            >
              About us
            </a>
            <a
              aria-label="JX Design's Startup Catalyst"
              className="redHover footer_p"
              href="/startup-catalyst"
            >
              Startup Catalyst
            </a>
            <a
              aria-label="JX Design's Frequently Asked Questions"
              className="redHover footer_p"
              href="/faq"
            >
              FAQ
            </a>
          </Row>
          <Row className="" style={{ marginTop: 40 }}>
            <div className="footerLocations">
              <div className="footer_p">Locations</div>

              <div className="footer_p">
                USA | Australia | Singapore | Sri Lanka | Dubai{" "}
              </div>
            </div>
          </Row>
        </Col>
      </div>
      <div className="footerContainer w414">
        <h1 className="footer_h1">Let's talk.</h1>
        <div className="footer_email_phone_container">
          <NavLink
            to="/contactus"
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: 20,
              marginBottom: 5,
            }}
          >
            <button type="button" class="btn footer-btn-info">
              Contact Us
            </button>
          </NavLink>
          <a
            aria-label="Give JX Design a call"
            href="tel:1 661 749 3778"
            className="footer_phone redHover"
          >
            +1 (661) 749 3778
          </a>
        </div>
        <div className="footerAddress">
          <div className="footer_p">28358 Constellation Road, Unit 600, </div>
          <div className="footer_p">Valencia, CA 91355, USA</div>
        </div>
        <div className="footerLinksContainer">
          <div className="footerLinks">
            <a
              aria-label="JX Design's Work"
              className="redHover footer_p"
              href="/case-studies"
            >
              Work
            </a>
            <a
              aria-label="JX Design's Solutions"
              className="redHover footer_p"
              href="/solutions"
            >
              Solutions
            </a>
            <a
              aria-label="About JX Design"
              className="redHover footer_p"
              href="/aboutus"
            >
              About us
            </a>
            <a
              aria-label="JX Design's Startup Catalyst"
              className="redHover footer_p"
              href="/startup-catalyst"
            >
              Startup Catalyst
            </a>
            <a
              aria-label="JX Design's Frequently Asked Questions"
              className="redHover footer_p"
              href="/faq"
            >
              FAQ
            </a>
          </div>
          <a
            aria-label="Navigate to the JX Design Homepage"
            className="jx_digital_design_agency_servicedesign_uxui_anchor"
            href="/"
          >
            <LazyLoadImage
              aria-label="Navigate to the JX Design Homepage"
              className="footer_jxLogo"
              alt="jx_digital_design_agency_servicedesign_uxui"
              src={jxsmall}
            />
          </a>
        </div>
        <div className="footer_socials footerTopRight">
          <a
            aria-label="JX Design's Facebook"
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.facebook.com/profile.php?id=100089495397503"
          >
            <i className="redHover fa-brands fa-facebook"></i>
          </a>
          <a
            aria-label="JX Design's Twitter"
            target="_blank"
            rel="noreferrer noopener"
            href="https://twitter.com/jxdrive"
          >
            <i className="redHover fa-brands fa-twitter"></i>
          </a>
          <a
            aria-label="JX Design's Instagram"
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.instagram.com/jxdrive/"
          >
            <i className="redHover fa-brands fa-instagram"></i>
          </a>
          <a
            aria-label="JX Design's Linkedin"
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.linkedin.com/company/jx-design/about/"
          >
            <i className="redHover fa-brands fa-linkedin"></i>
          </a>
          <a
            aria-label="JX Design's TikTok"
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.tiktok.com/@jxdrive?lang=en"
          >
            <i className="redHover fa-brands fa-tiktok"></i>
          </a>
          <a
            aria-label="JX Design's Youtube"
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.youtube.com/@jxdrive/featured"
          >
            <i className="redHover fa-brands fa-youtube"></i>
          </a>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <a aria-label="Visit the Loonum homepage" href="https://loonum.com">
              <LazyLoadImage
                className="footer_loonumLogo"
                alt="jx_digital_design_agency_servicedesign_uxui"
                src={loonumgroupsmall}
              />
            </a>
            <p className="footer_p footer_copyright">JXTech.Ai @ 2024</p>
          </div>

          <ul className="footerLocations">
            <li className="footer_p">Locations</li>
            <li className="footer_p">USA</li>
            <li className="footer_p">AUSTRALIA</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
