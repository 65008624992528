import React, { useEffect, useRef, useState } from "react";
import emailjs from "emailjs-com";

const ContactUsForm = () => {
  const form = useRef();

  // State for form inputs
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  // State for errors
  const [errors, setErrors] = useState({});

  useEffect(() => {
    let onLoadSelector = document.querySelectorAll(".onLoadTransition");
    for (let i = 0; i < onLoadSelector.length; i++) {
      setTimeout(() => {
        onLoadSelector[i].classList.add("active");
      }, 100 + 20 * i);
    }
  }, []);

  // Form validation function
  const validateForm = () => {
    let validationErrors = {};

    // First Name validation
    if (!formData.firstName.trim()) {
      validationErrors.firstName = "First Name is required";
    }

    // Last Name validation
    if (!formData.lastName.trim()) {
      validationErrors.lastName = "Last Name is required";
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      validationErrors.email = "Invalid email format";
    }

    // Phone validation (simple numeric check)
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
    if (!formData.phone.trim()) {
      validationErrors.phone = "Phone number is required";
    } else if (!phoneRegex.test(formData.phone)) {
      validationErrors.phone = "Invalid phone number format";
    }

    // Message validation
    if (!formData.message.trim()) {
      validationErrors.message = "Message is required";
    }

    setErrors(validationErrors);

    // If there are no validation errors, return true
    return Object.keys(validationErrors).length === 0;
  };

  // Handle form submission
  const sendEmail = (e) => {
    e.preventDefault();

    // Perform validation
    if (!validateForm()) {
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Access from .env
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Access from .env
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID // Access from .env
      )
      .then(
        (result) => {
          // Clear the form
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
          });
          console.log("Email successfully sent!", result.text);
          alert("Message Sent!");
        },
        (error) => {
          console.error("Error sending email:", error.text);
          alert("Failed to send message. Please try again later.");
        }
      );
  };

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <section className="flex w-full">
      <div className="aboutus_meetjx">
        <div className="container">
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="col-md-8 p-4 aboutus_p rounded-end">
              <h1 className="onLoadTransition reveal aboutus_h1 aboutus_heading">
                Contact Us
              </h1>
              <div
                className="aboutus_p aboutus_heading text-black"
                style={{ width: "100%" }}
              >
                <span className="onLoadTransition reveal">
                  For an in depth discussion on your current digital presence
                  and where you’d like to take your business,
                </span>
                <span className="onLoadTransition reveal">
                  please fill out this form and we’ll get in contact within 24
                  hours.
                </span>
              </div>
              <form
                ref={form}
                onSubmit={sendEmail}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "48px",
                  paddingTop: 96,
                }}
              >
                <div className="row mb-3">
                  <div className="col">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      value={formData.firstName}
                      onChange={handleInputChange}
                    />
                    {errors.firstName && (
                      <small className="text-danger">{errors.firstName}</small>
                    )}
                  </div>
                  <div className="col">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      value={formData.lastName}
                      onChange={handleInputChange}
                    />
                    {errors.lastName && (
                      <small className="text-danger">{errors.lastName}</small>
                    )}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                  </div>
                  <div className="col">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNumber"
                      name="phone"
                      placeholder="+1 012 3456 789"
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                    {errors.phone && (
                      <small className="text-danger">{errors.phone}</small>
                    )}
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    placeholder="Write your message"
                    rows="2"
                    value={formData.message}
                    onChange={handleInputChange}
                    style={{
                      rounded: "8px",
                      backgroundColor: "white",
                      borderBottom: "1.5px solid #55595c",
                    }}
                  />
                  {errors.message && (
                    <small className="text-danger">{errors.message}</small>
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    paddingTop: "24px",
                  }}
                >
                  <button
                    type="submit"
                    className="btn text-white rounded-1"
                    style={{
                      backgroundColor: "#1C75BC",
                      textTransform: "none",
                      width: "232px",
                    }}
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsForm;
